import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContainerBookingComponent } from './components/container-booking.component';
import { ContainerBookingRoutingModule } from './container-booking-routing.module';
import { DropdownModule } from 'primeng/dropdown';
import { RadioButtonModule } from 'primeng/radiobutton';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ButtonModule } from 'primeng/button';
import { ImageModule } from 'primeng/image';
import { InputTextModule } from 'primeng/inputtext';
import { ToastModule } from 'primeng/toast';
import { MessageService } from 'primeng/api';
import {InputTextareaModule} from 'primeng/inputtextarea';
import { LowerCaseDirective } from 'src/app/shared/helpers/lowercase-directive';

@NgModule({
  declarations: [
    ContainerBookingComponent,
    LowerCaseDirective
  ],
  imports: [
    CommonModule,
    ContainerBookingRoutingModule,
    DropdownModule,
    ReactiveFormsModule, 
    RadioButtonModule,
    FormsModule,
    BrowserAnimationsModule,
    ButtonModule,
    ImageModule,
    InputTextModule,
    ToastModule,
    InputTextareaModule
  ],
  providers:[MessageService]
})
export class ContainerBookingModule { }
