import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HTTP_INTERCEPTORS, HttpErrorResponse } from '@angular/common/http';
import { catchError, finalize, Observable, throwError } from 'rxjs';
import { HTTPMonitoringService } from '../services/http-monitoring.service';
import { SubjectService } from '../services/subject.service.';
import { environment } from 'src/environments/environment';
const TOKEN_HEADER_KEY = "Authorization";

@Injectable()
export class HttpRequestInterceptor implements HttpInterceptor {

  constructor(private httpMonitoringService: HTTPMonitoringService,private subjectService: SubjectService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let authReq = req;
    authReq = this.addTokenHeader(req, environment.apiToken);
    this.httpMonitoringService.start();

    return next.handle(authReq).pipe(catchError(error => {
      if (error.status === 500){
        this.subjectService.error500Occurred(true);
      }
      return throwError(error);
    }),finalize(() => {
      console.log('After request');
      this.httpMonitoringService.stop();
  }));
  }
  
private addTokenHeader = (request: HttpRequest<any>, token: string) => {
  return request.clone({ headers: request.headers.set(TOKEN_HEADER_KEY, `Basic ${token}`) });
}
}


export const httpInterceptorProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: HttpRequestInterceptor, multi: true },
];
