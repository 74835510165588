import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Title } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class TitleService {

  constructor(private titleService: Title) {
  
  }

  setTitle(subTitle:string) {
    this.titleService.setTitle("Vinning - " + subTitle);
  }
}
