import { NgModule, isDevMode } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppComponent } from './app.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { AppRoutingModule } from './app-routing.module';
import { RouterModule } from '@angular/router';
import { ContainerBookingModule } from './modules/container-booking/container-booking.module';
import { NgxSpinnerModule } from 'ngx-spinner';
import { HttpClientModule } from '@angular/common/http';
import { httpInterceptorProviders } from './shared/helpers/http.interceptor';
import { MessageService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    HttpClientModule,
    RouterModule,
    BrowserModule,
    ContainerBookingModule,
    AppRoutingModule,
    ToastModule,
    NgxSpinnerModule.forRoot({ type: 'ball-circus' }),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: true,
      registrationStrategy: 'registerWhenStable:30000'
    })
  ],
  providers: [HttpClientModule,httpInterceptorProviders,MessageService],
  bootstrap: [AppComponent]
})
export class AppModule { }
