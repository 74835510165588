import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { MessageService } from 'primeng/api';
import { SubjectService } from './shared/services/subject.service.';
import { TitleService } from './shared/services/title.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  loading = true;


  constructor(
    private subjectService: SubjectService,
    private titleService: TitleService,
    private spinnerService: NgxSpinnerService,
    private messageService: MessageService
  ) {}

  ngOnInit() {
    this.subjectService.emitLoading.subscribe(async (value) => {
     if(value == true)   this.spinnerService.show();
     else this.spinnerService.hide();
    });

    this.subjectService.emitError500.subscribe(async (value) => {
      if(value == true)   {
        this.messageService.add({
          severity: "error",
          summary: "Försök att uppdatera den här sidan eller kontakta oss gärna om problemet kvarstår",
          detail: "Hoppsan! Något gick fel",
        });
      }
     });

    this.titleService.setTitle('Container Service');
  }

}
