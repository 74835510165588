import { Component, OnInit } from "@angular/core";
import { AbstractControl, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MessageService } from "primeng/api";
import { Company } from "src/app/shared/models/company";
import { ContainerType } from "src/app/shared/models/container-type";
import { ServiceType } from "src/app/shared/models/service-type";
import { Yard } from "src/app/shared/models/yard";
import { MasterDataService } from "src/app/shared/services/master-data.service";
import { ContainerBooking } from "../models/container-booking";
import { ContainerBookingService } from "../services/container-booking.service";
import { ActivatedRoute } from "@angular/router";
import { numericHyphenValidator } from "src/app/shared/helpers/numeric-hyphen-validator";

@Component({
  selector: 'app-container-booking',
  templateUrl: './container-booking.component.html',
  styleUrls: ['./container-booking.component.css'],
})
export class ContainerBookingComponent implements OnInit {
  containerTypes = new Array<ContainerType>();
  serviceTypes = new Array<ServiceType>();
  visibleServiceTypes = new Array<ServiceType>();
  companies = new Array<Company>();
  yards = new Array<Yard>();
  isVisibleContainerNumber: boolean = true;
  isVisibleContainerType: boolean = true;
  private formSubmitAttempt: boolean = false;
  submitted: boolean = false;

  containerBookingForm: FormGroup = this.fb.group({
    company: [null, [Validators.required as any]],
    yard: [null, [Validators.required as any]],
    isExsistingCustomer: [false, [Validators.required as any]],
    serviceType: [null, [Validators.required as any]],
    customerName: ['', [Validators.required as any]],
    externalNumber: ['', [Validators.required as any, numericHyphenValidator()]],
    reference: ['', [Validators.required as any]],
    email: [
      '',
      [
        Validators.required as any,
        Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$'),
      ],
    ],
    phoneNumber: ['', [Validators.required as any]],
    pickupAddress: ['', [Validators.required as any]],
    containerType: [null, [Validators.required as any]],
    containerNumber: [],
    comment: [],
  });

  formControllersArray: any;

  constructor(
    private masterDataService: MasterDataService,
    private fb: FormBuilder,
    private containerBookingService: ContainerBookingService,
    private messageService: MessageService,
    private activatedRoute: ActivatedRoute
  ) {}

  onInput(event: any) {
    const input = event.target.value;
    const sanitizedInput = input.replace(/[^0-9-]/g, '');
    if (input !== sanitizedInput) {
      event.target.value = sanitizedInput;
    }
    this.containerBookingForm.controls['externalNumber'].setValue(sanitizedInput);
  }

  async ngOnInit() {
    await this.getMasterData();

    this.readURLPrams();

    this.containerBookingForm.controls['company'].valueChanges.subscribe(
      (selectedValue) => {
        if (selectedValue) this.getYards(selectedValue.id);
      }
    );

    this.containerBookingForm.controls[
      'isExsistingCustomer'
    ].valueChanges.subscribe((selectedValue) => {
      this.onChangedIsExistingCustomer(selectedValue);
    });

    this.containerBookingForm.controls['serviceType'].valueChanges.subscribe(
      (selectedValue) => {
        if (selectedValue && selectedValue.isVisibleContainerNumber == true)
          this.isVisibleContainerNumber = true;
        else {
          this.isVisibleContainerNumber = false;
          this.containerBookingForm.controls['containerNumber'].reset();
        }

        if (selectedValue && selectedValue.isVisibleContainerType == true) {
          this.isVisibleContainerType = true;
          this.containerBookingForm.controls['containerType'].setValidators(
            Validators.required as any
          );
        } else {
          const requiredValidator = Validators.required;
          this.containerBookingForm.controls['containerType'].reset();
          this.containerBookingForm.controls['containerType'].removeValidators(
            requiredValidator
          );
          this.containerBookingForm.controls[
            'containerType'
          ].updateValueAndValidity();
          this.isVisibleContainerType = false;
        }
      }
    );

    this.onChangedIsExistingCustomer(false);
  }

  convertToLowercase() {
    this.containerBookingForm.get('email')?.setValue(this.containerBookingForm.get('email')?.value.toLowerCase());
  }

  readURLPrams() {
    this.activatedRoute.queryParams.subscribe((params) => {
      let companyId = Number(params['company']);
      let yardId = Number(params['yard']);
      var selectedCompany = this.companies.find((x) => x.id == companyId);

      if (this.companies.length > 0 && selectedCompany && !isNaN(companyId)) {
        this.containerBookingForm.controls['company'].setValue(selectedCompany);
        this.setYard(companyId,yardId);
      }
    });
  }

  mobileNumber(event:any): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode != 43 && charCode != 32) {
      return false;
    }
    return true;
  }

  async setYard(companyId: number, yardId: number) {
    await this.getYards(companyId);
    var selectedYard = this.yards.find((x) => x.id == yardId);
    if (this.yards.length > 0 && selectedYard && !isNaN(yardId)) {
      this.containerBookingForm.controls['yard'].setValue(selectedYard);
    }
  }

  get isRequiredPhoneNumber(): boolean {
    return this.hasRequiredValidator('phoneNumber');
  }

  get isRequiredPickupAddress(): boolean {
    return this.hasRequiredValidator('pickupAddress');
  }

  get isRequiredEmail(): boolean {
    return this.hasRequiredValidator('email');
  }

  hasRequiredValidator(control: string): boolean {
    const requiredValidator = Validators.required;
    if (
      this.containerBookingForm.get(control)?.hasValidator(requiredValidator)
    ) {
      const validators = this.containerBookingForm.get(control)!.validator!(
        {} as AbstractControl
      );
      if (validators!.hasOwnProperty('required')) {
        return true;
      }
    }
    return false;
  }

  onChangedIsExistingCustomer(isSelected: boolean) {
    this.getRequiredServiceType();
    if (!isSelected) {
      this.containerBookingForm.controls['pickupAddress'].setValidators(
        Validators.required as any
      );
      this.containerBookingForm.controls[
        'pickupAddress'
      ].updateValueAndValidity();
      this.containerBookingForm.controls['email'].setValidators([Validators.required as any,Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]);
      this.containerBookingForm.controls['email'].updateValueAndValidity();
      this.containerBookingForm.controls['phoneNumber'].setValidators(
        Validators.required as any
      );
      this.containerBookingForm.controls[
        'phoneNumber'
      ].updateValueAndValidity();
    } else {
      const requiredValidator = Validators.required;

      this.containerBookingForm.controls['pickupAddress'].removeValidators(
        requiredValidator
      );
      this.containerBookingForm.controls[
        'pickupAddress'
      ].updateValueAndValidity();
      this.containerBookingForm.controls['email'].removeValidators(
        requiredValidator
      );
      this.containerBookingForm.controls['email'].updateValueAndValidity();
      this.containerBookingForm.controls['phoneNumber'].removeValidators(
        requiredValidator
      );
      this.containerBookingForm.controls[
        'phoneNumber'
      ].updateValueAndValidity();
    }
  }

  async getMasterData() {
    this.containerTypes = await this.masterDataService.getContainerTypes();
    this.serviceTypes = await this.masterDataService.getServiceTypes();
    this.getRequiredServiceType();
    this.companies = await this.masterDataService.getCompanies();
  }

  async getYards(companyid: number) {
    this.yards = await this.masterDataService.getYards(companyid);
  }

  onClickImage(containerType: ContainerType) {
    if (containerType.id) {
      this.containerBookingForm.controls['containerType'].setValue(
        containerType
      );
    }
  }

  getRequiredServiceType() {
    this.visibleServiceTypes = new Array<ServiceType>();
   if(this.containerBookingForm.controls['isExsistingCustomer'].value == false){
    this.serviceTypes.forEach((res)=>{
      if(res.id != 2 && res.id != 3){
        this.visibleServiceTypes.push(res);
      }
    });
   }else{
    this.visibleServiceTypes = this.serviceTypes;
   }
  }

  bindDefualtValues() {
    this.containerBookingForm.controls['isExsistingCustomer'].setValue(false);
  }

  submit(model: ContainerBooking) {
    this.submitted = true;
    if (this.containerBookingForm.valid) {
      this.containerBookingService
        .create(this.createRequestBody(model))
        .subscribe(
          (data: ContainerBooking) => {
            this.containerBookingForm.reset();
            this.isVisibleContainerNumber = true;
            this.isVisibleContainerType = true;
            this.yards = new Array<Yard>();
            this.bindDefualtValues();
            this.messageService.add({
              severity: 'success',
              summary: 'Din för frågan har skickats',
              detail: 'Förfrågan skickad',
            });
            this.submitted = false;
          },
          (err) => {
            this.submitted = false;
          }
        );
    } else {
      this.validateAllFormFields(this.containerBookingForm);
    }
  }

  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(this.containerBookingForm.controls).forEach((field) => {
      if (formGroup.controls[field].invalid) {
        formGroup.controls[field].markAsDirty({ onlySelf: true });
      }
    });
  }

  createRequestBody(model: ContainerBooking): ContainerBooking {
    var cloneModel: ContainerBooking = Object.assign({}, model);

    if (cloneModel.company && cloneModel.company.id)
      cloneModel.companyId = cloneModel.company.id;
    if (cloneModel.serviceType && cloneModel.serviceType.id)
      cloneModel.serviceTypeId = cloneModel.serviceType.id;
    if (cloneModel.yard && cloneModel.yard.id)
      cloneModel.yardId = cloneModel.yard.id;
    if (cloneModel.containerType && cloneModel.containerType.id)
      cloneModel.containerTypeId = cloneModel.containerType.id;
    cloneModel.company = undefined;
    cloneModel.yard = undefined;
    cloneModel.serviceType = undefined;
    cloneModel.containerType = undefined;

    return cloneModel;
  }
}
