import { Injectable } from "@angular/core";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Yard } from "../models/yard";
import { catchError, Observable, throwError } from "rxjs";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class MasterDataService {
  private path: string;
  private baseUrl: string = "";

  constructor(private http: HttpClient) {
    this.path = "api/v1/MasterData";
    this.baseUrl = environment.apiUrl;
  }

  async getContainerTypes() {
    return this.http
      .get<any>(this.baseUrl + this.path + "/container-types")
      .toPromise();
  }

  async getServiceTypes() {
    return this.http
      .get<any>(this.baseUrl + this.path + "/service-types")
      .toPromise();
  }

  async getCompanies() {
    return this.http
      .get<any>(this.baseUrl + this.path + "/companies")
      .toPromise();
  }

  getYards(companyId: number) {
    return this.http.get<any>(this.baseUrl + this.path + "/yards/" + companyId).toPromise();
  }
}
