import { Injectable } from '@angular/core';
import { Subject, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SubjectService {
  public notificationSubject = new Subject();
  public overlaySubject = new Subject();
  public spinnerSubject = new Subject();
  public loadingSubject = new BehaviorSubject<any>(null);
  public error500Subject = new BehaviorSubject<any>(null);

  constructor() { }

  public addNotification(resultJson: any): void {
    this.notificationSubject.next(resultJson);
  }

  public removeOverlay(): void {
    this.overlaySubject.next(0);
  }

  public addSpinner(): void {
    this.spinnerSubject.next(true);
  }

  public removeSpinner(): void {
    this.spinnerSubject.next(false);
  }

  public loading(value:boolean): void {
    this.loadingSubject.next(value);
  }

  get emitLoading(): BehaviorSubject<any> {
    return (this.loadingSubject as BehaviorSubject<any>);
  }

  public error500Occurred(value:boolean): void {
    this.error500Subject.next(value);
  }

  get emitError500(): BehaviorSubject<any> {
    return (this.error500Subject as BehaviorSubject<any>);
  }

}
