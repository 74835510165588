<div class="d-flex align-items-center">
    <div class="container p-5">
        <div class="row justify-content-center">
            <div class="col-12 com-sm-12 col-md-12 col-lg-10 bg-white p-4 shadow-sm">
                <div class=" row ">
                    <div class="col-12 col-sm-12 col-md-12 col-lg-8">
                        <div class="row">
                            <div class="col-12">
                                <div class="w-75">
                                    <p-image src="assets/imgs/vinning_logo_.png" alt="Image" width="250"
                                        class="fadeIn"></p-image>
                                    <p class="small w-75 py-1">Vi rustar en traditionell bransch inom järn- och
                                        metallåtervinning för
                                        morgondagens behov</p>
                                    <hr style="height:4px;border-width:0;color:gray;background-color:#62BB46"
                                        class="mb-2">
                                    <h5 class="mt-3 mb-4">Boka tjänst</h5>
                                </div>
                            </div>
                            <div class="col-12 col-sm-12 col-md-12 col-lg-6">
                                <form [formGroup]="containerBookingForm" class="form-horizontal">
                                    <div class="mb-2">
                                        <label for="exampleInputEmail1" class="form-label fw-bold label-required">Företag</label>
                                        <div>
                                            <p-dropdown formControlName="company" [options]="companies" placeholder="Välj företag" 
                                                optionLabel="companyName"
                                                styleClass="dropdown-custom-width p-inputtext-sm"></p-dropdown>
                                                <small *ngIf="containerBookingForm.controls['company'].dirty && containerBookingForm.controls['company'].invalid && submitted"
                                                id="customerName-help" class="p-error block">Nödvändig</small>
                                        </div>
                                    </div>
                                    <div class="mb-2">
                                        <label for="exampleInputEmail1" class="form-label fw-bold label-required">Anläggning</label>
                                        <div>
                                            <p-dropdown formControlName="yard" placeholder="Välj anläggning"  [options]="yards"
                                                optionLabel="yardName" styleClass="dropdown-custom-width p-inputtext-sm"></p-dropdown>
                                                <small *ngIf="containerBookingForm.controls['yard'].dirty && containerBookingForm.controls['yard'].invalid && submitted"
                                                id="customerName-help" class="p-error block">Nödvändig</small>
                                        </div>
                                    </div>
                                    <div class="mb-2">
                                        <div>
                                            <label class="form-check-label fw-bold " for="groupname">
                                                Befintlig kund
                                            </label>
                                        </div>

                                        <div class="form-check form-check-inline ps-0">
                                            <p-radioButton name="isExsistingCustomer" [value]="true"
                                                formControlName="isExsistingCustomer"></p-radioButton>
                                            <label class="form-check-label ms-2 " for="isExsistingCustomer">
                                                Ja
                                            </label>
                                        </div>

                                        <div class="form-check form-check-inline ps-1">
                                            <p-radioButton name="isExsistingCustomer" [value]="false"
                                                formControlName="isExsistingCustomer"></p-radioButton>
                                            <label class="form-check-label ms-2 " for="isExsistingCustomer">
                                                Nej
                                            </label>
                                        </div>
                                    </div>
                                    <div class="mb-2">
                                        <label for="exampleInputEmail1" class="form-label fw-bold label-required">Typ av tjänst</label>
                                        <div>
                                            <p-dropdown formControlName="serviceType" placeholder="Välj tjänst" [options]="visibleServiceTypes"
                                                optionLabel="name" styleClass="dropdown-custom-width  p-inputtext-sm">
                                            </p-dropdown>
                                                
                                                <small *ngIf="containerBookingForm.controls['serviceType'].dirty && !containerBookingForm.controls['serviceType'].valid && submitted"
                                                id="customerName-help" class="p-error block">Nödvändig</small>
                                        </div>
                                    </div>
                                    <div class="mb-2">
                                        <label for="customerName" class="form-label fw-bold label-required">Kundnamn</label>
                                        <div>
                                            <input formControlName="customerName" type="text" pInputText
                                                aria-describedby="customerName-help" class="ng-invalid ng-dirty"
                                                class="dropdown-custom-width p-inputtext-sm" maxlength="50"/>
                                            <small
                                                *ngIf="containerBookingForm.controls['customerName'].dirty && !containerBookingForm.controls['customerName'].valid"
                                                id="customerName-help" class="p-error block">Nödvändig</small>
                                        </div>
                                        <div class="mb-2">
                                            <label for="externalNumber" class="form-label fw-bold label-required">Org / personnummer</label>
                                            <div>
                                                <input (input)="onInput($event)" type="tel" inputmode="tel" formControlName="externalNumber" pInputText
                                                    class="ng-invalid ng-dirty  p-inputtext-sm" class="dropdown-custom-width p-inputtext-sm"
                                                    aria-describedby="externalNumber-help"  maxlength="15"/>
                                                <small
                                                    *ngIf="containerBookingForm.controls['externalNumber'].dirty && !containerBookingForm.controls['externalNumber'].valid"
                                                    id="externalNumber-help" class="p-error block">Nödvändig</small>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>

                            <div class="col-12 col-sm-12 col-md-12 col-lg-6">
                                <form [formGroup]="containerBookingForm" class="form-horizontal">
                                    <div class="mb-2">
                                        <label for="exampleInputEmail1" class="form-label fw-bold label-required">Referens / märkning</label>
                                        <div>
                                            <input type="text" formControlName="reference" pInputText
                                                class="ng-invalid ng-dirty p-inputtext-sm" class="dropdown-custom-width p-inputtext-sm"
                                                maxlength="100" aria-describedby="reference-help" />
                                            <small
                                                *ngIf="containerBookingForm.controls['reference'].dirty && !containerBookingForm.controls['reference'].valid"
                                                id="reference-help" class="p-error block">Nödvändig</small>
                                        </div>
                                    </div>
                                    <div class="mb-2">
                                        <label for="exampleInputEmail1" class="form-label fw-bold" [ngClass]="{'label-required': isRequiredEmail}">Email</label>
                                        <div>
                                            <input [email]="true" type="text" maxlength="100"  id="email" (input)="convertToLowercase()" formControlName="email" pInputText
                                                class="ng-invalid ng-dirty p-inputtext-sm" aria-describedby="email-help"
                                                class="dropdown-custom-width p-inputtext-sm"/>
                                            <small
                                                *ngIf="containerBookingForm.controls['email'].dirty && containerBookingForm.controls['email'].errors?.['required']"
                                                id="email-help" class="p-error block">Nödvändig</small>
                                                <small
                                                *ngIf="containerBookingForm.controls['email'].dirty && containerBookingForm.controls['email'].errors?.['pattern']"
                                                id="email-help" class="p-error block">Ogiltig</small>
                                        </div>
                                    </div>
                                    <div class="mb-2">
                                        <label for="exampleInputEmail1" class="form-label fw-bold" [ngClass]="{'label-required': isRequiredPhoneNumber}">Telefonnummer</label>
                                        <div>
                                            <input type="tel" maxlength="14" inputmode="tel" (keypress)="mobileNumber($event)" formControlName="phoneNumber" pInputText
                                                class="ng-invalid ng-dirty  p-inputtext-sm" aria-describedby="phoneNumber-help"
                                                class="dropdown-custom-width  p-inputtext-sm" />
                                            <small
                                                *ngIf="containerBookingForm.controls['phoneNumber'].dirty && !containerBookingForm.controls['phoneNumber'].valid"
                                                id="phoneNumber-help" class="p-error block">Nödvändig</small>
                                        </div>
                                    </div>
                                    <div class="mb-2">
                                        <label for="exampleInputEmail1" class="form-label fw-bold" [ngClass]="{'label-required': isRequiredPickupAddress}">Upphämtningsadress</label>
                                        <div>
                                            <textarea class="ng-invalid ng-dirty" maxlength="1000" class="form-control" pInputTextarea formControlName="pickupAddress"
                                                aria-describedby="pickupAddress-help" id="exampleFormControlTextarea1"
                                                rows="7"></textarea> <br/>
                                                <small
                                                *ngIf="containerBookingForm.controls['pickupAddress'].dirty && !containerBookingForm.controls['pickupAddress'].valid"
                                                id="pickupAddress-help" class="p-error block">Nödvändig</small>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-sm-12 col-md-12 col-lg-4">
                        <div class="row">
                            <form [formGroup]="containerBookingForm" class="form-horizontal" (ngSubmit) = "submit(containerBookingForm.value)">
                                <div class="col-12">
                                    <div class="bg-light rounded mb-2 p-2">
                                        <div class="mb-2" *ngIf="isVisibleContainerType">
                                            <label for="exampleInputEmail1" class="form-label fw-bold label-required">Typ av container</label>
                                            <div>
                                                <p-dropdown placeholder="Välj typ av container" formControlName="containerType" [options]="containerTypes"
                                                    optionLabel="name" styleClass="dropdown-custom-width p-inputtext-sm"></p-dropdown>
                                                    <small
                                                    *ngIf="containerBookingForm.controls['containerType'].dirty && !containerBookingForm.controls['containerType'].valid"
                                                    id="containerType" class="p-error block">Nödvändig</small>
                                            </div>
                                        </div>

                                        <div class="d-flex flex-wrap w-100 justify-content-between" *ngIf="isVisibleContainerType">
                                            <ng-container *ngFor="let containerType of containerTypes; index as i;">
                                                <div class="col text-center me-1 border border-1" [ngClass]="{'border-primary': containerBookingForm.controls['containerType'].value?.id == containerType.id, 'border-secondary': containerBookingForm.controls['containerType'].value?.id != containerType.id}" *ngIf="containerType.thumbnailImageURL">
                                                    <p-image  (click)="onClickImage(containerType)" [src]="containerType.thumbnailImageURL" alt="Image" width="100%"
                                                        class="fadeIn "></p-image>
                                                </div>
                                            </ng-container>
                                        </div>
                                        <div class="d-flex flex-column w-100 mt-2 bg-white p-2" *ngIf="!isVisibleContainerType">
                                            <div *ngIf="containerBookingForm.controls['serviceType'].value && containerBookingForm.controls['serviceType'].value.imageURL">
                                                <p-image [preview]="true"
                                                    [src]="containerBookingForm.controls['serviceType'].value.imageURL"
                                                    alt="Image" width="100%" class="fadeIn"></p-image>
                                            </div>
                                        </div>

                                        <div class="d-flex flex-column w-100 mt-2 bg-white p-2"  *ngIf="containerBookingForm.controls['containerType'].value && containerBookingForm.controls['containerType'].value.id">
                                            <div *ngIf="containerBookingForm.controls['containerType'].value && containerBookingForm.controls['containerType'].value.imageURL">
                                                <p-image [preview]="true"
                                                    [src]="containerBookingForm.controls['containerType'].value.imageURL"
                                                    alt="Image" width="100%" class="fadeIn"></p-image>
                                            </div>
                                            <div class="small"
                                                [innerHTML]="containerBookingForm.controls['containerType'].value.descriptionHTML">

                                            </div>
                                        </div>
                                    </div>
                                    <div class="mb-2" *ngIf="isVisibleContainerNumber">
                                        <label for="exampleInputEmail1" class="form-label fw-bold" maxlength="50">Containernummer</label>
                                        <div>
                                            <input type="text" formControlName="containerNumber" pInputText
                                                class="ng-invalid ng-dirty  p-inputtext-sm" aria-describedby="containerNumber-help"
                                                class="dropdown-custom-width  p-inputtext-sm" />
                                            <small
                                                *ngIf="containerBookingForm.controls['containerNumber'].dirty && !containerBookingForm.controls['containerNumber'].valid"
                                                id="containerNumber-help" class="p-error block">Nödvändig</small>
                                        </div>
                                    </div>
                                    <div class="mb-2">
                                        <label for="exampleInputEmail1" class="form-label fw-bold">Kommentar</label>
                                        <div>
                                            <textarea maxlength="1000" class="form-control" formControlName="comment"
                                                aria-describedby="comment-help" id="exampleFormControlTextarea1"
                                                rows="2"></textarea>
                                        </div>
                                    </div>
                                 
                                    <button pButton pRipple type="submit"
                                        label="Skicka in" class="p-button-success w-100"></button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>