import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import constants from '../../shared/constants';
import { ContainerBookingComponent } from './components/container-booking.component';

const routes: Routes = [{ path: constants.AppRouterPath.booking, component: ContainerBookingComponent}];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ContainerBookingRoutingModule { }
